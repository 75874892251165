import './style.scss';
import NavigationBar from './navigation';
function Header(){
    return(
        <div className='Head'>
            <NavigationBar/>
        </div>
    );
};

export default Header;