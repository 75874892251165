import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ServicesDetail from "./servicesDetail";


function Services(){

    return(
        <div>
            <Header/>
            <div className='stateOfArt'>
                <div className='stateOfArtContent'>
                    <p className='stateOfArtText'>OUR SERVICES</p>
                   
                    
                </div>
                
                
            </div>
            <ServicesDetail/>
            <Footer/>
        </div>
    );
};

export default Services;