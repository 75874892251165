import { Images } from '../../assets'; 
import CustomLocation from '../customlocation';
import {AiOutlineInstagram} from "react-icons/ai";
import {AiOutlineTwitter} from "react-icons/ai";
import {ImFacebook} from "react-icons/im";
import {ImVimeo} from "react-icons/im";
import {AiOutlineMail} from "react-icons/ai";
import {BsTelephone} from "react-icons/bs";
import "./style.scss";

function Footer(){

    const iconHeight={
        height: '1.75vw',
        width: '1.75vw',
        marginRight:'2.92vw'

    };
    const privacyStyle={
        textDecoration:'none'
    }
    return(
        <div className='footerClass'>
            <footer>
            <p className='startYourJourney'>Start Your Journey to Better Business</p>
            <hr className='footerLine'/>

            <div className='footerContent'>
                <div className='locationSection'>
                <CustomLocation style={{color: '#8c8c8c'}} location="16405 Terrace " address="Dr. Austin, TX 78728" />
                <CustomLocation style={{color: '#8c8c8c'}} location="16405 Terrace " address="Dr. Austin, TX 78728" />
                <CustomLocation style={{color: '#8c8c8c'}} location="16405 Terrace " address="Dr. Austin, TX 78728" />
                     </div>
                <div className='information'>
                    <div className='phoneInfo'>
            
                        <BsTelephone style={{color: '#C41826'}} size='2.71vw'/>
                        <p className='phone'>+1 678 799-1112</p>
                    </div>
                    <div className='mailInfo'>
                        {/* <img src={Images.mail} alt='mail' style={{width:"11.71vw"}}/> */}
                        <AiOutlineMail style={{color: '#C41826', marginLeft:'3vw'}} size='2.71vw'/>
                        <p className='mail'><a href="mailto:info@allytelecom.net">info@allytelecom.net</a></p>
                    </div>
                   
                </div>
                <div className='googleMap'>
                        {/* <img src={Images.map} alt='map' className='googleMapImg'/> */}
                    </div>
            </div>
            

            <div className='footerBottom'>

                <div className='copyright'>
                    <p className='copyrightText'>Copyright ©Ally Telecom | All Rights Reserved.</p>
                </div>
                <div className='icons'>
                    <AiOutlineInstagram style={iconHeight}/>
                    <AiOutlineTwitter style={iconHeight}/>
                    <ImFacebook style={iconHeight}/>
                    <ImVimeo style={iconHeight}/>
                </div>
                <div className='termOfUsePrivacyPolicy'>
                    <a href='#' style={privacyStyle} className='termOfUse'>Terms of use</a>
                    <a href='#' style={privacyStyle} className='privacyPolicy'>Privacy Policy</a>
                </div>

            </div>
            <div className='bottom-line'>
  Ready to take the next step? Reach out to us at &nbsp;
  <a href="mailto:info@allytelecom.net">info@allytelecom.net</a>&nbsp;
  for all the details and exciting business prospects!
</div>


            </footer>
        </div>
        
    );
};

export default Footer;