import {SlLocationPin} from 'react-icons/sl'; 
import "./style.scss";

function CustomLocation(props){

    return (
        <div className='location'>
            <div className='locationIcon'>
            <SlLocationPin style={{color:'#C41826', fontSize:'3.5vw', paddingRight:'0.73vw'}} />
            </div>
            <p className='locationtext'>{props.location}<br/>{" "}{props.address}</p>
        </div>

    );
};

export default CustomLocation;