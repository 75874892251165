import {Images} from '../../assets/index';
import Header from '../../components/header';
import CompanyTrust from './companyTrust';
import AboutUs from './aboutUs';
import Services from './services';
import Footer from '../../components/footer';

import "./style.scss";


function Home(){

    return (
        <body>
            <Header/>
            <div className='stateOfArt'>
                <div className='stateOfArtContent'>
                    <p className='stateOfArtText'>FUELING BUSINESS SUCCESS THROUGH OPERATIONAL OPTIMIZATION!</p>
                    <div className='buttonDiv'>
                        <button className='gettingStartedButton'>Getting Started</button>
                        <button className='learnMoreButton'>Learn More</button>
                    </div>
                    
                </div>
                
                
            </div>
            {/* <div className='companyTrust'>

            </div>
             */}
            <AboutUs/>
            <Services/>
            {/* <CompanyTrust/> */}
            <Footer/>
            
        </body>
    );
};

export default Home;