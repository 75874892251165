import home from "./home.png";
import shade from "./Rectangle.png";
import background from "./background.png";
import client1 from "./client1.png";
import client2 from "./client2.png";
import curly from "./curly.png";
import about_us from "./about_us_img.png";
import mail from "./mail.png";
import phone from "./phone.png";
import map from "./map.png";
import curlywhite from "./curlywhite.png";
import service from "./service.png";
import icon from "./icon.png";
import icon3 from "./icon3.png";
import icon4 from "./icon4.png";
import logo from "./logo.jpg";
import service1 from "./service1.png";
import service2 from "./service2.png";
import service3 from "./service3.png";
import service4 from "./service4.png";


export const Images = {
  home,
  shade,
  background,
  client1,
  client2,
  curly,
  about_us,
  mail,
  phone,
  map,
  curlywhite,
  service,
  icon,
  icon3,
  icon4,
  logo,
  service1,
  service2,
  service3,
  service4
};