import React, { useState } from 'react';
import './style.scss';
import {Images} from "../../../assets/index";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {BsChevronDown} from 'react-icons/bs';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


function NavigationBar() {

  return (
    <Navbar expand="lg" className="bg-white">
      <Container fluid>
        <Navbar href="/" className='header-logo'><img src={Images.logo} className='header-img'/></Navbar>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0 navContent"
            // style={{ maxHeight: '100px' }}
            navbarScroll>

          <NavDropdown
              title={
                  <span>
                     About us <BsChevronDown/>
                  </span>
              }
              id='navbarScrollingDropdown'>
              <NavDropdown.Item href="#action3" className='eee'>Action</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Another action </NavDropdown.Item>
              <NavDropdown.Item href="#action5">Something else here</NavDropdown.Item>
            </NavDropdown>
            <Link to="/winningFormula" id="navWithoutDrop" style={{textDecoration:'none'}}>Winning Formula</Link>
            <Link to="/services" id="navWithoutDrop" style={{textDecoration:'none'}}>Services</Link>
            
            <NavDropdown
              title={
                  <span>
                     Life at Ally Telecom <BsChevronDown/>
                  </span>
              }
              id='navbarScrollingDropdown'>
              <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Another action </NavDropdown.Item>
              <NavDropdown.Item href="#action5">Something else here</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="#action2" id="navWithoutDrop">Careers</Nav.Link>
            
          </Nav>
            {/* <Button type='button'className='btn custom-button'>Contact us</Button> */}
              <button type='button' className='custom-button'>Contact us</button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
