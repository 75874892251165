import { Images } from '../../../assets/index'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomService from '../../../components/customservice';
import "./style.scss";

function Services(){
    return(
        <div className='mainService'>
            <div className='service'>
                {/* <div className='serviceContent'>
                    <p className='ourDoor'>OUR DOORS NEVER CLOSE</p>
                   
                    <img src={Images.curlywhite} alt='curl' className='serviceCurlyLine'/>
                </div> */}
                <p className='ourServices'>Our Services</p>
            </div>
            
            
            <div className='Container'>
                <div className='col-1'></div>
                <div className='col-2 service-col'>
                <CustomService title="Online Retail Sales" explanation="At Ally Telecom, we provide an array of online retail sales services, ensuring our clients can showcase and distribute their products efficiently in the digital marketplace."/>
                </div>
                <div className='col-2 service-col'>
                    <CustomService title="Customer Support" explanation="Dedicated to excetional customer experiences, Ally Telecom adeptly handles customer inquiries, ensuring swift resolutions and maintaining the brand integrity of our clients."/>
                </div>
                <div className='col-2 service-col'>
                    <CustomService title="Technical Assistance" explanation="Our technical assistance team at Ally Telecom is well-equipped to provide detailed product information, helping clients and their customers understand and utilize products to their fullest potential.
"/>
                </div>
                <div className='col-2 service-col'>
                    <CustomService title ="Transportation Services" explanation="Understanding the complexities of logistics, Ally Telecom's transportation services are designed to proficiently manage customer inquiries, ensuring timely and accurate responses related to transport matters."/>
                </div>
                <div className='col-2 service-col-last'>
                    <CustomService title="Accounting and Finance Services" explanation="Financial accuracy is paramount. At Ally Telecom, our comprehensive suite of accounting and finance services encompasses bookkeeping, tax services, and more, ensuring our clients' financial matters are handled with precision and expertise."/>
                </div>
                <div className='col-1'></div>

            </div>
        </div>

    );
};

export default Services;