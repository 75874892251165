import CustomClient from '../../../components/customclient';
import { Images } from '../../../assets';
import "./style.scss";

function AboutUs(){
    return(
        <div className='AboutUsContent'>
            {/* <p className='whoWeAre'>WHO WE ARE</p> */}
           
            <div className='aboutUsLeft'>
                    <p className='aboutUs'>About us</p>
                    {/* <img src={Images.curly} alt='curly' className='curly'/> */}
                    <p className='AboutUsExplanation'>Originating from Austin, Texas, Ally Telecom, LLC stands as a premier, cost-effective outsourcing
organization. We cater to a variety of businesses, offering services that help them optimize their costs.
Our offerings range from online retail sales and customer support to technical assistance, logistics, and
accounting and finance services. One of our core strengths is our advanced technological infrastructure.
When combined with our relentless commitment to staff training and development, it guarantees firstrate services. As we operate globally, Ally Telecom's exceptional team and strategic approach underscore
our unwavering commitment to client success. </p>
            </div> 
            <div className='aboutUsRight'>
                <img src={Images.about_us} alt='about us' className='aboutUsImage'/>
            </div>  

        </div>
    );
};

export default AboutUs;