import React from "react";
import "./style.scss";


function CustomService(props){
    // const explanationParagraphs = explanation.split('\n').map((paragraph, index) => (
    //     <p key={index}>{paragraph}</p>
    // ));
    return(
        <div className="cService">
            <p className="cServiceTitle">{props.title}</p>
            {/* <p className="cServiceExplanation">dangerouslySetInnerHTML={{ __html: explanation }}</p>             */}
            <p className="cServiceExplanation">{props.explanation}</p>
        </div>
    );
};

export default CustomService;