import Header from "../../components/header";
import Footer from "../../components/footer";
import WinningDetail from "./winningDetail";
import "./style.scss";

function WinningFormula(){

    return(
        <div>
            <Header/>
            <div className='stateOfArt'>
                <div className='stateOfArtContent'>
                    <p className='stateOfArtText'>WINNING FORMULA</p>
                    
                    
                </div>
                
                
            </div>
            <WinningDetail/>
            <Footer/>

        </div>
    );
};
export default WinningFormula;