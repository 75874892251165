import Home from './pages/home';
import WinningFormula from './pages/winningFormula';
import Services from './pages/services';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.scss';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route path='winningFormula' element={<WinningFormula/>}/>
          <Route path='services' element={<Services/>}/>
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
